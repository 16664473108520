import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {

    static targets = [ "counter" ]


    connect() {
            const modal = new Modal(this.element);
            modal.show();
    }
}
