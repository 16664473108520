import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = [ "inputImage" ]

    connect() {

        this.inputImageTargets.forEach((inputImage) => {

            inputImage.setAttribute('style', 'background-color: #ced4da')

            let parent = inputImage.parentElement;
            let imageName = inputImage.dataset.name
            let imageUrl = inputImage.dataset.url

            let splitUrl = imageUrl.split('.')
            let format = splitUrl[splitUrl.length - 1]

            if ( format === 'pdf') {

                if (inputImage.dataset.url !== "") {
                    let elt = document.createElement('a')
                    elt.href =  '../'+inputImage.dataset.url
                    elt.setAttribute('class','text-decoration-none mx-auto m-3')
                    elt.setAttribute('target','_blank')
                    elt.innerText = 'Voir le pdf'
                    parent.insertBefore(elt, parent.firstChild);
                } else {
                    let elt = document.createElement('p')
                    elt.setAttribute('class','text-decoration-none mx-auto m-3')
                    elt.innerText = "Aucun pdf n'a été chargé"
                    parent.insertBefore(elt, parent.firstChild);
                }

            } else {

                if (inputImage.dataset.url !== "") {
                    let elt = document.createElement('img')
                    elt.src = '../'+inputImage.dataset.url
                    elt.id = imageName + '_image'
                    elt.setAttribute('class','img-fluid mx-auto border m-3')
                    elt.setAttribute('style','max-height: 300px; background-color: #e9ecef')
                    parent.insertBefore(elt, parent.firstChild);
                } else {
                    let elt = document.createElement('p')
                    elt.setAttribute('class','text-decoration-none mx-auto m-3')
                    elt.innerText = "Aucune image n'a été chargé"
                    parent.insertBefore(elt, parent.firstChild);
                }
            }
        })
    }

    uploadImage(event) {

        this.element.querySelector(".spinner-border").style.display = "block"
        this.element.querySelector(".fa-circle-check").style.display = "none"
        this.element.querySelector(".fa-circle-exclamation").style.display = "none"

        let targetName = event.target.dataset.name;
        let prevue = document.querySelector('#' + targetName + '_image')

        let files = event.target.files;
        let file = files[0];

        if (file.type.match('image/jpeg') || file.type.match('image/png')  || file.type.match('image/jpg')   || file.type.match('application/pdf') ) {

            const formData = new FormData();
            formData.append("fileAjax", file );

            fetch('/tableaudebord/parameter/upload-image-ajax/' + targetName, {
                method: 'POST',
                headers: { "X-Requested-with": "XMLHttpRequest" },
                body: formData,
            })
                .then((response) => {
                    return response.text();
                })
                .then((retourFile) => {

                    this.element.querySelector(".spinner-border").style.display = "none"

                    if (retourFile === "ok") {

                        this.element.querySelector(".fa-circle-check").style.display = "block"

                        if (file.type.match('application/pdf')) {

                            let elt = document.createElement('a')
                            elt.href =  '../images/pdf/'+targetName+'.pdf'
                            elt.setAttribute('class','text-decoration-none mx-auto m-3')
                            elt.setAttribute('target','_blank')
                            elt.innerText = 'Voir le pdf'
                            if (event.target.parentElement.querySelector("p")) {
                                event.target.parentElement.querySelector("p").style.display = "none"
                            }

                            event.target.parentElement.insertBefore(elt, event.target.parentElement.firstChild);


                        } else {
                            prevue.src = URL.createObjectURL(file);
                        }
                    } else {
                        this.element.querySelector(".fa-circle-exclamation").style.display = "block"
                    }
                    console.log(retourFile);
                });

        }



    }

}
