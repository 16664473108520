import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = [ "counter" ]

    connect() {
        console.log("hello")
        let inputLogo = document.querySelector("#inscription_salon_admin_logo_file");
        let imagePreview = document.querySelector("#imagePreview");
        let logoLabel = document.querySelector("#logoLabel");
        let logoError = document.querySelector(".logoError");

        function logoInputAction() {
            logoError.style.display = "none";
            let files = inputLogo.files;
            let file = files[0];

            if( file && file.size <= 5000000 ){

                if (file.type.match('image.*')) {

                    if (file) {
                        imagePreview.style.display = "block";
                        logoLabel.innerText = "Changer de logo";
                        imagePreview.src = URL.createObjectURL(file);
                        document.querySelector(".logoError").style.display = "none";
                    }
                } else {
                    logoError.style.display = "block";
                    logoError.innerText = 'Le format du fichier est incorrect';
                }
            } else {
                logoError.style.display = "block"
                logoError.innerText = 'Le fichier et trop volumineux';
            }
        }

        inputLogo.addEventListener( 'input' , function() {

            logoInputAction();

        })


    }

}
