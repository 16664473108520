//import "@stimulus/polyfills";

import { default as AppendContentController } from "@stimactiv/core/dist/append-content_controller";
import { default as DownloadableController } from "@stimactiv/core/dist/downloadable_controller";
import { default as DropdownController } from "@stimactiv/core/dist/dropdown_controller";
import { default as FormCollectionController } from "@stimactiv/core/dist/form-collection_controller";
import { default as FormChoicesController } from "@stimactiv/core/dist/form-choices_controller";
import { default as FormReloadController } from "@stimactiv/core/dist/form-reload_controller";
import { default as FormWizardController } from "@stimactiv/core/dist/form-wizard_controller";
import { default as ModalController } from "@stimactiv/core/dist/modal_controller";
import { default as SwalLoaderController } from "@stimactiv/core/dist/swal-loader_controller";
import { default as ToastController } from "@stimactiv/core/dist/toast_controller";
import { default as TooltipController } from "@stimactiv/core/dist/tooltip_controller";

import { default as ReportErrorController } from "@stimactiv/log/dist/controller";

import { startStimulusApp } from '@symfony/stimulus-bridge';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));

// register any custom, 3rd party controllers here

app.register('stimactiv--core--append-content', AppendContentController);
app.register('stimactiv--core--downloadable', DownloadableController);
app.register('stimactiv--core--dropdown', DropdownController);
app.register('stimactiv--core--form-collection', FormCollectionController);
app.register('stimactiv--core--form-choices', FormChoicesController);
app.register('stimactiv--core--form-reload', FormReloadController);
app.register('stimactiv--core--form-wizard', FormWizardController);
app.register('stimactiv--core--modal', ModalController);
app.register('stimactiv--core--swal-loader', SwalLoaderController);
app.register('stimactiv--core--toast', ToastController);
app.register('stimactiv--core--tooltip', TooltipController);

app.register('stimactiv--log--report-error', ReportErrorController);
