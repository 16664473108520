import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = [ "counter" ]

    connect() {

        this.element.style.color = 'red';
        console.log(this.hasCounterTarget)
        if (this.hasCounterTarget) {
            console.log(this.counterTarget)
        }
    }

    increment(event) {
        if (this.hasCounterTarget) {
            const target = this.counterTarget;
            target.innerHTML = (Number(target.textContent) ?? 0) + Number(event.currentTarget.dataset.azerty);
        }
    }
}
